.demo_class {
    margin: 2rem auto;
    padding: 1rem;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.demo_class .container {
    width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.demo_class .col-1 {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.demo_class .col-1 p:nth-child(1) {
    font-size: 1.8rem;
    font-style: italic;
}

.demo_class .col-1 p:nth-child(2) {
    font-size: 3rem;
    font-weight: 600;
    margin: .5rem 0;
}

.demo_class .col-1 p:nth-child(3) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}


.demo_class .col-2 {
    margin: auto;
}


@media screen and (max-width: 1000px) {
    .demo_class .container {
        max-width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    .demo_class iframe {
        width: 100%;
        height: auto;
    }
}











