.about {
    background-color: #f8f8f8;
    margin: auto;
    padding: 8rem 1rem 0 1rem;
    width: 100%;
}

.about .container {
    margin: auto;
    width: 940px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow-x: hidden;
    overflow-y: hidden;
}

.about h2 {
    font-size: 3rem;
}

.about img {
    width: 50%;
}

.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.about .col-2 p:first-of-type {
    margin: 2rem 0;
}

.about .col-2 p:nth-child(4) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
}

.about button {
    margin-top: 1rem;
}

@media screen and (max-width:940px) {
    .about .container {
        grid-template-columns: 1fr;
    }
    .about .container {
        width: 100%;
    }

    .about .container img {
        padding: 0rem;
        width: 60vw;
        margin: auto;
    }

    .about .container .col-2 {
        padding: 1rem;
    }
    .col-2{
        text-align: center;
    }

    .about button {
        margin-bottom: 1rem;
    }
}









