.footer {
  width: 100%;
  background-color: #000;
  color: #eee;
  padding: 3rem 1rem;
}

.footer .container {
  max-width: 1240px;
  margin: auto;
  text-align: center;
}

.footer ul {
  display: flex;
  justify-content: center;
}

.footer li {
  padding: 1rem;
}

.footer li a {
  color: #fff;
}

.office {
  color: var(--secondary-color);
}

.footer li a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.bottom {
  text-align: center;
  padding-top: 3rem;
}

.contact-elements {
  margin: auto;
  margin-top: 20px;
  width: 80%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-item {
  display: flex;
  align-items: center;
  margin: 1rem;
}

.icon {
  margin-right: 0.5rem;
}

.address-width {
  width: 220px;
}
.address-line {
  height: 130px;
  border: 2px solid var(--secondary-color);
}

@media screen and (max-width: 1000px) {
  .contact-elements {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 550px;
  }
  .address-line {
    transform: rotate(90deg);
  }
  .footer li {
    padding: 0.5rem;
  }
}
