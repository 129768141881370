/* styles.css */

.services-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 150px 0px 100px 0px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.services-card {
  width: 500px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.services-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.services-card img {
  width: 95%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.services-card h2 {
  color: var(--primary-color);
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.services-card p {
  color: #666;
  font-size: 0.8rem;
}

.margin-left {
  margin-left: 400px;
}

.margin-right {
  margin-right: 400px;
}

@media screen and (max-width: 1000px) {
  .margin-left {
    margin-left: 0px;
  }

  .margin-right {
    margin-right: 0px;
  }

  .services-container {
    margin: 150px 0px 100px 0px;
  }

  .services-card {
    width: 90%;
    padding: 20px;
    margin: 10px;
  }
}
