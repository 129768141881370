.testimonials {
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.testimonials .container {
    margin: 4rem auto;
    padding: 2rem 0rem 1rem 0rem;
}

.testimonials h2 {
    font-size: 3rem;
    text-align: center;
}

.testimonials .container .content {
    display: flex;
    margin: 4rem 0;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);
    padding: 1rem;
    width: 300px;
    height: 150px;
    text-align: center;
    margin-right: 20px; /* Add a margin for spacing between cards */
}

.testimonials img {
    width: 60%;
    max-height: 80%;
    margin-top: auto;
}

.testimonials .card p {
    color: #787878;
    font-size: 0.5rem;
    margin-top: auto;
}

.testimonials .card span {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 600;
}

