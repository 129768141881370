.contact {
    padding: 50px;
    max-width: 500px;
    margin: auto;
    margin-top: 70px;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 8px;
}

input,
textarea {
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    color: #555;
}
.error {
    color: lightcoral;
    font-size: 12px;
}

input:hover,
textarea:hover {
    border-color: #ffad01;
}

/* Media query for small screens */
@media screen and (max-width: 600px) {
    .contact {
        max-width: 100%;
    }
}
